.main-house {
  @apply relative h-full w-full md:max-w-[550px] flex flex-wrap  rounded-[20px] mx-auto px-2;

  &__container {
    @apply container max-w-[430px] relative h-[380px]  mx-auto ;

    &__maison-ifpen {
      @apply absolute max-w-xs w-[280px] top-0 left-0;
    }

    &__battery-charge-input-flow {
      @apply absolute top-56 left-[45px]  max-w-xs;
    }

    &__battery-charge-output-flow {
      @apply absolute top-56 left-[45px] max-w-xs;
    }

    &__batterie-ifpen {
      @apply absolute max-w-[45px] top-[150px] left-[33px] max-h-20 ;
    }

    &__compteur-ifpen {
      @apply  w-[75px] left-[230px] top-[220px] absolute;
    }

    &__poteau-ifpen {
      @apply absolute w-[75px] left-[302px] top-[70px] max-w-xs max-h-48;
    }

    &__external-consumption-flow-label {
      @apply absolute top-[210px] left-[260px] bg-[#E00012] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #8a010dbd;
    }

    &__external-consumption-flow {
      @apply  top-[240px] left-[290px] absolute;
    }

    &__supplier-flow {
      @apply  top-[240px] left-[290px] absolute;
    }

    &__supplier-flow-label {
      @apply absolute top-[210px] left-[260px] bg-[#E02FEF] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #8f1f99d8;
    }

    &__supplier-flow-label-none {
      @apply absolute top-[210px] left-[260px] bg-[#EAEAEA] rounded-full text-center px-3 py-1 text-black font-bold;
      text-shadow: 0px 0px 3px #0000005c;
    }

    &__solar-flow {
      @apply absolute top-[112px] left-[157px] max-w-xs;
    }

    &__solar-flow-label {
      @apply absolute top-[150px] left-[224px]  bg-[#ebdc0e] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 4px #9c930abd;
    }

    &__consumption-flow {
      @apply  top-[205px] left-[120px] absolute;
    }

    &__consumption-flow-label {
      @apply absolute left-[170px] top-[250px] bg-[#DADADA] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #1f1f1fcc;
    }

    &__battery-input-flow-label {
      @apply absolute top-[285px] left-[25px] bg-[#43D820] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #2e9717b9;
    }

    &__battery-input-flow-label-none {
      @apply absolute top-[285px] left-[25px] bg-[#EAEAEA] rounded-full text-center px-3 py-1 text-black font-bold;
      text-shadow: 0px 0px 3px #0000005c;
    }

    &__battery-output-flow-label {
      @apply absolute top-[285px] left-[25px] bg-[#10AD77] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #0a6b49cb;
    }
  }
  &__top {
    @apply w-full flex flex-row items-center mx-auto;
  }

  &__widgets {
    @apply w-full flex justify-between my-2 gap-2 overflow-scroll max-w-full px-2;
  }
   /* Hide scrollbar for Chrome, Safari and Opera */
   .main-house__widgets::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .main-house__widgets {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}