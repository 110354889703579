.switcher {
    @apply flex justify-between  max-h-[165px] flex-wrap;

    &__head {
        @apply flex justify-between text-[#0487FB] font-semibold text-base w-full;
    }

    &__period {
        @apply w-full flex flex-wrap md:flex-row gap-2 md:gap-1 justify-center mt-3 font-semibold text-xs;

        &__control {
            @apply rounded-2xl border border-[#0087FF] px-3 py-1 text-[#0087FF] mx-2;

            &--active {
                @apply rounded-2xl border border-transparent bg-[#0087FF] mx-2 px-3 py-1 text-white;
            }
        }
    }

    &__unit {
        @apply w-full flex justify-center text-[#0087FF] font-semibold;

        &__control {
            @apply px-5 pt-5 pb-2 border-b-[#0087ff] border-b-2;
        }

        &__control--active {
            @apply px-5 pt-5 pb-2 border-b-[#0087ff]  mt-[1px] border-b-4;
        }

    }

    &__content {
        @apply w-full flex justify-around;
    }
}