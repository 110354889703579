.container-weather {
    @apply  w-screen h-full;
    &__left {
        @apply  w-full ;
    }
    &__right {
        @apply  w-full flex flex-col ;
    }

}
 /* Hide scrollbar for Chrome, Safari and Opera */
 .list::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .list {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
