.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 40px;
  }
  
   
   