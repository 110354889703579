.labeled-bar-chart {
    @apply  h-full w-full flex min-h-fit flex-col grow  w-full flex flex-col   gap-1 my-auto;
    &__legend {
        @apply hidden md:flex justify-center gap-9 my-5 items-center;

        &__image {
            @apply rounded-full  h-14 w-14 flex justify-center items-center transition-all duration-500;
        }

        &-text {
            @apply flex items-center gap-2 text-sm md:text-base;
        }
    }

    &__legend-col { 
        @apply flex flex-wrap   md:hidden md:flex-row gap-4  mx-auto w-full  ;

        &-text {
            @apply flex items-center gap-2 text-sm md:text-base;
        }
        &__image {
            @apply rounded-full w-10 h-10  flex justify-center items-center transition-all duration-500 ;
        } 
    }
} 