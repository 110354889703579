.app-bar {
  @apply  absolute  w-full w-screen z-50  h-20 md:h-16 fixed inset-x-0 bottom-0;
  &__image {
    @apply w-[105px]  h-10 ;
}
}
.navbar {
  color: white;
  bottom: 0;
  width: 100%;
  text-align: center;
}
 
