.circular-div {
    @apply   w-full h-full; 
    border-radius: 50%; /* Pour obtenir une forme circulaire */
    position: relative;
  }
  
  .half-circle-left {
    @apply  w-full h-full;
    position: absolute;
    clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
    transform: rotate(45deg);
    border-radius: 50%;
  }
  .half-circle-right {
    @apply  w-full h-full;
    position: absolute;
    clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
    transform: rotate(225deg); /* Pour obtenir la moitié droite */
    border-radius: 50%;
  }
  .circle-image {
    @apply w-5 h-5 md:w-8 md:h-8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Pour centrer l'image */
    max-width: 80%; /* Ajustez la taille de l'image selon vos besoins */
    max-height: 80%;
  }