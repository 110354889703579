.site {
    @apply flex flex-col gap-y-4  mx-auto md:mx-0    border-b-2 shadow-md p-2;
    &___image-container {
        @apply h-[200px] rounded-md  w-full bg-slate-300;
        &__image {
            @apply w-full h-full;
        }
    }
    &__title {
        @apply  text-[#003265] text-xl  md:text-2xl  font-['Titillium_Web_Bold']  pl-2;
    }
    &__address {
        @apply text-[#767676]  pl-2;
    }
    &__value {
        @apply  text-[#003265] font-bold  pl-2;
    }
}
