@use  './assets/scss/_base/_fonts.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

body, html{ 
  @apply md:overflow-y-auto;
}
 
#root {
  @apply md:overflow-y-auto;
}