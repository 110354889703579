.invoice-panel {
    @apply bg-[#0888F9] rounded-3xl text-white m-auto p-4 w-full;
    box-shadow: 0px 8px 24px #0488F93D;

    &__head {
        @apply text-center;

        &__title {
            @apply uppercase font-semibold pb-1;
        }
    }

    &__body {
        @apply flex justify-around items-center mt-2;

        &__content {
            @apply text-center px-4;

            &__sum {
                @apply font-semibold text-xl;

                & + p {
                    @apply text-xs;
                }
            }
        }

        &__content:nth-child(2) {
            @apply border-x-[1px] w-full;
        }
    }
}