@font-face {
    font-family: "Titillium Web Black";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-Black.woff2');
}

@font-face {
    font-family: "Titillium Web Bold";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-Bold.woff2');
}

@font-face {
    font-family: "Titillium Web BoldItalic";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-BoldItalic.woff2');
}

@font-face {
    font-family: "Titillium Web ExtraLight";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-ExtraLight.woff2');
}

@font-face {
    font-family: "Titillium Web ExtraLightItalic";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-ExtraLightItalic.woff2');
}

@font-face {
    font-family: "Titillium Web Italic";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-Italic.woff2');
}

@font-face {
    font-family: "Titillium Web Light";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-Light.woff2');
}

@font-face {
    font-family: "Titillium Web LightItalic";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-LightItalic.woff2');
}

@font-face {
    font-family: "Titillium Web Regular";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-Regular.woff2');
}

@font-face {
    font-family: "Titillium Web SemiBold";
    src: url('/assets/fonts/titilliumweb/TitilliumWeb-SemiBold.woff2');
}