.panel {
    @apply flex flex-col w-full   bg-white rounded-[20px] p-4 md:p-2 m-auto ;
    box-shadow: 5px 8px 10px #B4B4B466;

    &__title {
        @apply flex justify-between text-center w-full mt-2;

        & h3 {
            @apply text-base font-bold uppercase text-[#003265]  max-w-[70%] break-words;
        }
    }
}