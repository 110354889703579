.card {
    @apply py-2 max-h-fit sm:min-w-[110px] max-w-[140px] w-32 overflow-visible bg-white/50 rounded-md px-2;

    &__title {
        @apply text-center text-xxs font-['Titillium_Web_Bold'] leading-tight  text-[#003265] uppercase;
    }

    &__subtitle {
        @apply text-center text-xxs text-[#003265] font-semibold ;
    }

    &__text {
        @apply text-center text-xs font-light  text-[#0087FF] leading-tight mb-4;
    }
}