.login-desktop {
    @apply hidden md:flex flex-row h-full h-full; 
} 
.rightSide {
    @apply bg-[url('src/assets/img/landingPageImage.png')]  bg-repeat bg-cover  bg-right-bottom  flex-none md:w-1/2  min-h-screen grow hidden md:flex ; 
}
.leftSide {
    @apply flex-none w-full h-full border-red-500 min-h-screen md:w-1/2 p-10 md:p-1   mx-auto;
}
.login-mobile {
    @apply flex md:hidden h-screen w-full bg-[url('src/assets/img/landingPageImage.png')]  bg-no-repeat bg-cover bg-left-bottom ; 
} 